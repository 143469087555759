.lobby ul{
    width : 100vw;
    height: 100vh;
    padding-top: 56px;

    display: block;
    list-style: none;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    position : relative;
    z-index : 0;
}
.lobby ul li{
    display: inline-block;
    white-space: pre-line;
    vertical-align: middle;
    padding-bottom : 66px;
}
/* ---------------------------------------------------- */
/* .lobby .content{
    opacity: 0;
    transition: opacity 1s linear;
} */
.lobby .content .item-text {
    width : 330px;
    margin-right : 117px;
}
.lobby .content .item-text .title{
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 18px;
}
.lobby .content .item-text .desc{
    font-size : 16px;
    line-height: 1.5;
}

/* ---------------------------------------------------- */
.lobby .content .item-title{
    margin-left : 75px;
    position : relative;
    left : -200px;
    margin-right : 0px;
}

.lobby .content .item-text .subTitleLine {
    margin-top: 10px;
    margin-bottom: 30px;
}

/* ---------------------------------------------------- */
.lobby .content .item-entrance{
    width : 600px;
    margin-right: 150px;
}
.lobby .content .item-entrance .flex-row-wrapper{
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: flex-end;
    margin-right: 100px;
}

.lobby .content .item-entrance .thumbnail{
    width : 400px;
    height : 80vh;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
    border : 5px solid;
}


.lobby .content .item-entrance .info{
    display: block;
    width : 300px;
    background-color: white;
    padding : 20px;
    margin-bottom: 8vh;
    position: relative;
    left : -60px;
}
.lobby .content .item-entrance .info .title{
    font-size : 24px;
    font-weight: bold;
    margin-bottom: 7px;
}
.lobby .content .item-entrance .info .desc{
    font-size : 12px;
}

/* ---------------------------------------------------- */
.lobby .content .item-intro{
    margin-left: 10vw;
    height: 100%;
    margin-top: auto;
    padding-bottom: 0;
}
.lobby .content .intro-img{
    height : 90%;
    vertical-align: middle;
}

.lobby .content .flex-vertical-wrapper {
    height : 100%;
    display: flex;
    align-items: center;
}

/* ---------------------------------------------------- */
.lobby .content .nextLevel{
    margin: 0;
    margin-right : 1vw;
    padding : 20px;

    height : 100%;
    width : fit-content;
    width: -moz-fit-content;
    vertical-align: top;
}
.lobby .content .nextLevel .stair {
    height: 100%;
    border: solid 2px;
    padding : 10px;
}
.lobby .content .nextLevel .stair>img{
    border : 1px solid #161616;
    height: 100%;
}