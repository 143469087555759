body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", "Gothic A1",sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color : #191918;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
    margin : 0; padding : 0;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* *::-webkit-scrollbar {
    display: none;
} */

a  {
    text-decoration: none;
    color : inherit !important;
}

.main{
    width : 100vw;
    padding : 56px 100px 0 100px;
}
.main .section{
    width : 1240px;
    margin : 0 auto;
    margin-bottom: 200px;
}
.main .section .title{
    font-size: 40px;
    font-weight: 800;
    padding : 50px 0;
}
.main .section .text{
    white-space: pre-line;
    font-size :24px;
    line-height: 1.88;
}
.main .section .subTitle{
    font-size: 28px;
    font-weight: 800;
    padding : 25px 0;
}
.main .section .subText{
    white-space: pre-line;
    font-size :18px;
    line-height: 1.88;
}

select {
    /* init <input> setting */
    border : none;
    border-radius: 0;
    /* 브라우저별 기본 스타일링 제거 */
    appearance: none;
    -webkit-appearance: none;  
    -moz-appearance: none; 
    /* 포커스시 발생하는 효과 제거 */
    outline-style: none;

    width : 3em;
    background: url('./data/arrow.png') no-repeat 95% 50%;
    background-size: 0.75em;
}
textarea{
    resize: none;
}

.googleForm{
    width : 100%;
    margin: 0 auto;
    height : 100%;
}

@media screen and (max-width : 1440px){
    .main .section{
        width : 100%;
    }
}