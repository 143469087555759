.gallery{
    overflow-x: hidden;
}

.gallery .intro .title{
    font-size: 80px;
}
.gallery .intro .desc{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom : 50px;
}
.gallery .intro .desc .info{
    width : 25%;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.88;
}
.gallery .intro .desc .text{
    width : 75%;
    font-size: 24px;
    line-height: 1.88;
}
/* ------------------------------------------- */

.gallery .detail .item{
    margin-bottom: 40px;
}
.gallery .detail .item img{
    width : 100%;
    height: intrinsic;
}
.gallery .detail .desc{
    white-space: pre-line;
    font-size: 20px;
    line-height: 1.88;
}
.gallery .detail .link{
    width : 60%;
    margin : 0 auto 80px auto;
}
.gallery .detail .link .linkedImage{
    width : 100%;
    border : 10px solid;
}

.gallery .detail .slider{
    width: 100%;
}
.gallery .detail .slider img{
    border : 1px solid #ccc;
}

.gallery .detail .slider .rec-arrow{
    border : none;
    color : #bbb;
}
.gallery .detail .slider .rec-arrow:hover:enabled{
    background-color: transparent;
    color : #777;
}

/* ------------------------------------------- */

.gallery .artists .flex-row-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.gallery .artists .item{
    text-align: center;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gallery .artists .video{
    padding : 0;
}
.gallery .artists .item .profile {
    width : 250px;
    height : 250px;
    border-radius: 125px;
    border : solid 1px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.gallery .artists .item .info {
    margin: 20px 0;
    font-size: 20px;
}
.gallery .artists .item .info .name {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 2px;
}
.gallery .artists .item .info .major{
    font-size: 16px;
    margin-bottom: 8px;
}
.gallery .artists .item .info .link{
    margin : 0 auto;
    width: intrinsic; /* safari */
    width : -moz-fit-content; /* firefox */
    width : -webkit-fit-content; /* chrome */
}
.gallery .artists .item .info .link .goToLink{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px;
}

/* ------------------------------------------- */
.gallery .goLobby{
    width : 400px;
    margin : 0 auto 100px auto;
    padding : 50px 70px;

    border : 1px solid;
    border-radius: 200px;
    transition : background-color 0.2s ease-in-out;
}
.gallery .goLobby:hover{
    color : white;
}
.gallery .goLobby:hover .line{
    stroke : white;
}

.gallery .goLobby .flex-row-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gallery .goLobby .title{
    font-size: 30px;
    font-weight: 800;
}
.gallery .goLobby .text{
    font-size: 24px;
    font-weight: 800;
}


/* ------------------------------------------- */
@media screen and (max-width : 1440px){
    .gallery .section{
        width : 100%;
    }
    .gallery .detail .link{
        width : 75%;
    }
}
@media screen and (max-width : 1024px){
    .gallery .detail .link{
        width : 100%;
    }
}