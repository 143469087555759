.level1{
    overflow-x: hidden;
}
.level1 .section .emphasis{
    font-size: 80px;
    padding-bottom : 0px;
}
.level1 .logs .log{
    width: 100%;
    height : 300px;
    margin : 10px;
    padding : 40px;
    overflow: scroll;
    color : #161616;
    white-space: pre-line;

    border : 3px solid;
    border-bottom: 7px solid;
    border-radius: 20px;
    
    display : flex;
    flex-direction: column;
    overflow-x: hidden;
}
.level1 .logs .log::-webkit-scrollbar{
    display: none;
}
.level1 .logs .log .info{
    margin-bottom: 20px;
}
.level1 .logs .log .info .name{
    font-size: 20px;
    font-weight: 800;   
}
.level1 .logs .log .comment{
    height : auto;
    font-weight: 800;
    font-size: 30px;
}
/* -------------------------------- */
.level1 .logs .form{
    width : 600px;
    margin : 120px auto 200px auto;
    padding : 50px 70px;

    border : solid 3px;
    border-bottom: 7px solid;
    border-radius: 20px;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.level1 .logs .form .formTitle{
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 50px;
}
.level1 .logs .form .item{
    width : 100%;
    margin-bottom: 40px;
}
.level1 .logs .form .item>label{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.level1 .logs .form .item .myInput{
    /* init <input> setting */
    line-height: 1.88;
    border-radius: 0;
    outline-style: none; /* 포커스시 발생하는 효과 제거를 원한다면 */ 
    -webkit-appearance: none; /* 브라우저별 기본 스타일링 제거 */ 
    -moz-appearance: none; 
    appearance: none;

    width : 100%;
    padding : 10px 10px 10px 0px;
    font-family: inherit;
    font-size : 24px;
    border : none;
    border-bottom: 2px solid #bbb;
    background-color: transparent;

    transition : border 0.2s ease-in-out;
}
.level1 .logs .form .item .myInput:focus{
    border-color: #161616;
    border-bottom: 3px solid;
}

.level1 .logs .form button{
    outline-style: none; /* 포커스시 발생하는 효과 제거를 원한다면 */ 
    -webkit-appearance: none; /* 브라우저별 기본 스타일링 제거 */ 
    -moz-appearance: none; 
    appearance: none;

    margin-top: 20px;
    width : 100%;
    height : 75px;
    font-size: 20px;
    font-weight: 800;

    border : solid 2px #161616;
    border-radius: 75px;
    background-color: transparent;
}

.level1 .logs .form button:active{
    background-color: #161616;
    color : white;
}

/* -------------------------------- */
.level1 .stores .store{
    width : 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;
}
.level1 .stores .store .info{
    width : 40%;
}
.level1 .stores .store .content{
    margin-top: 75px;
    width : 60%;
}
.level1 .stores .store .form{
    height : 600px;
    overflow-y: scroll;
}

.level1 .stores .docent .schedule{
    margin-top: 10px;
}

/* ------------------------------- */
.level1 .goHome{
    width : 400px;
    margin : 0 auto 100px auto;
    padding : 50px 70px;

    border : 1px solid;
    border-radius: 200px;
    transition : background-color 0.2s ease-in-out;
}
.level1 .goHome:hover{
    background-color: #161616;
    color : white;
}
.level1 .goHome:hover .line{
    stroke : white;
}

.level1 .goHome .flex-row-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.level1 .goHome .title{
    font-size: 30px;
    font-weight: 800;
}
.level1 .goHome .text{
    font-size: 24px;
    font-weight: 800;
}


/* -------------------------------- */
/* Carousel */
.rec.rec-arrow {
    background: none;
    border : none;
    box-shadow: none;
    color : #777;
}
.rec.rec-arrow:hover:enabled, .rec.rec-arrow:focus:enabled {
    background : none;
    color : #777;
    box-shadow: none;
}
.rec.rec-arrow:hover:enabled{
    color: #000;
}
.rec.rec-arrow:disabled {
    visibility: hidden;
}
.level1 .carousel_wrapper{
    cursor: grab;
}


@media screen and (max-width : 1024px){
    .level1 .stores .store{ flex-direction: column; }
    .level1 .stores .store .info{ width : 100%; }
    .level1 .stores .store .form{ width : 100%; }
}