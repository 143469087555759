.credit{
    overflow-x: hidden;
}
.credit .title{
    text-align: center;
}
.credit .section {
    margin-bottom: 100px;
}
.credit .flex-row-wrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}
.credit .item {
    width: 230px;
    text-align: center;
    margin : 20px 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.credit .item .profileHolder{
    width : 200px;
    height : 200px;
    border-radius: 100px;
    border : 2px solid #161616;
    background-color: white;
    background-size: cover;
    background-position: center;
}

.credit .CD .urim{
    width : 250px;
    height: 250px;
    border-radius: 50%;
    margin-bottom: 20px;
}


/* for table 786 - 1024 */
@media screen and (max-width:1024px){
    .credit .section{ width : 100%;}
    
}